// :root {
//     --fontFamilyBase: 'Source Sans Pro', sans-serif;
//     --fontBaseColor: rgba(0, 0, 0, 0.6);
//     --backgroundColor: #fffbf7;
//     --colorBrand: #236143;
//     --colorBrandDark: #062f1b;
//     --colorBlack: #000000;
//     --colorWhite: #fff;
// }

$colorBlackFill10: rgba(0, 0, 0, 0.08);
$colorBlackFill20: rgba(0, 0, 0, 0.16);
$colorBlackFill30: rgba(0, 0, 0, 0.6);
$colorBlackFill40: rgba(0, 0, 0, 0.8);

$colorBrand: #236143;
$colorBrandDark: #062f1b;
$colorBrandLight: #f3f5ec;

$colorFormError: #ffdede;

$colorSecondary: #fee8d0;
$colorSecondaryLight: #fffbf7;
$colorSecondaryDark: #d4ac81;

$colorWhite: #fff;
$colorBlack: #000;

// Gray scale, increments by ten, so that we can put in stuff in between in future
$colorGray10: #f5f5f5;
$colorGray20: #e7e7e7;
$colorGray30: #d9d9d9;
$colorGray40: #c9c9c9;
$colorGray50: #b8b8b8;
$colorGray60: #a6a6a6;
$colorGray70: #919191;
$colorGray80: #787878;
$colorGray90: #595959;
$colorGray100: #262626;

$fontFamilyBase: 'Source Sans Pro', sans-serif;
$fontBaseColor: $colorBlackFill30;

$fontWeightSemiBold: 600;
$fontWeightRegular: 400;
$fontWeightLight: 200;
