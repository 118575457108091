@import 'styles/includes.nordr.scss';

.ShortFacts {
    $root: &;

    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    color: $colorGray100;
    background-color: $colorWhite;
    flex-wrap: wrap;

    &--WithinCardProject {
        align-items: flex-start;
    }

    &--NoStatus {

    }

    &--CardExternalProject {
        padding: 28px 15px 33px;
    }

    &--occupied,
    &--sold-out {
        //border-bottom: 4px solid $colorRust100;
    }

    &--upcoming,
    &--vip-release {
        //border-bottom: 4px solid $colorBlueLight100;
    }

    &--active {
        //border-bottom: 4px solid $colorBlueDark100;
    }

    &__Content {
        padding: 16px 24px 25px;
        width: 100%;
    }

    &__Text {
        margin-bottom: 15px;
        padding-bottom: 15px;
        font-size: 1.8rem;
        line-height: 22px;
        //border-bottom: 1px solid $colorGray20;

        @include media(lg) {
            margin-bottom: 15px;
            //padding-bottom: 35px;
            //font-size: 2.4rem;
            font-size: 2.2rem;
            line-height: 32px;
        }

        &:empty {
            display: none;
        }

        #{$root}--CardExternalProject#{$root}--MainProject & {
            border-bottom: 0;
        }
    }

    &__Name {
        margin-bottom: 5px;
        font-weight: 500;
        line-height: 1.38;
        font-size: 1.6rem;
        letter-spacing: 0.5px;

        .ListObjects & {
            //line-height: 1.38;
        }

        &:empty {
            display: none;
        }
    }

    &__Area {
        margin-bottom: 16px;
        font-size: 1.4rem;
        line-height: 1.5;

        .ListObjects & {
            //border-bottom: 1px solid $colorGray30;
            letter-spacing: 0.3px;
            //padding-bottom: 20px;
        }

        &:empty {
            display: none;
        }
    }

    &__TableCell {
        padding: 5px 0 5px 0;
        font-size: 1.4rem;
        font-weight: 500;
        color: $colorBlack;
        letter-spacing: 0.4px;

        &--Value {
            width: 100%;
            font-weight: normal;
            font-size: 1.4rem;
            letter-spacing: 0.3px;
            text-align: right;
        }

        #{$root}__TableRow--SaleStatus & {
            display: none;

            &--Value {
                display: block;
                width: auto;
                height: auto;
                position: absolute;
                top: 10px;
                right: 10px;
                font-size: 1.2rem;
                font-weight: 500;
                padding: 8px 24px;
                background-color: $colorBlueDark100;
                color: $colorWhite;

                #{$root}--upcoming &,
                #{$root}--vip-release & {
                    color: $colorBlack;
                    background-color: $colorBlueLight60;
                }

                #{$root}--sold &,
                #{$root}--sold-out &,
                #{$root}--occupied & {
                    background-color: $colorRust100;
                }

                #{$root}--CardExternalProject & {
                    top: 10px;
                    right: unset;
                    left: -120px;
                }
            }
        }
    }

    &__MainProject {
        font-size: 1.2rem;
        line-height: 1.33;
        letter-spacing: 0.4px;
        font-weight: 500;
        padding-bottom: 13px;
        margin-bottom: 12px;
        border-bottom: 1px solid $colorBrownLight40;
        color: $colorBlack;
        display: flex;
        align-items: center;

        &:before {
            display: block;
            content: '';
            width: 14px;
            height: 14px;
            background-image: url('#{$basepath}svg/icon-project-nordr.svg');
            background-repeat: no-repeat;
            background-size: contain;
            margin-right: 10px;
        }

        span {
            font-weight: 500;
        }
    }

    &__Button {
        display: none;
    }

    &__Message {
        width: 100%;
        text-align: center;
        padding: 15px 24px 16px;

        &--dark-secondary {
            color: $colorWhite;
            background-image: $backgdImgGradientLeft;
        }

        &--brand {
            color: $colorWhite;
            background-image: $backgdImgGradientLeft;
        }

        &--brand-light {
            color: $colorWhite;
            background-image: $backgdImgGradientBottom;
        }
    }

    &__MessageContent {
        text-align: left;
    }

    &__MessageContainer {
        display: flex;
        justify-content: left;
        align-items: normal;
    }

    &__MessageTitle::before {
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        flex-shrink: 0;
        display: inline-block;
        margin: -5px 10px -5px 0;


        #{$root}__Message--sale & {
            content: '';
            background-image: url('#{$basepath}svg/icon-hero-sale-white-transparent.svg');
            height: 20px;
            width: 20px;
        }

        #{$root}__Message--sale#{$root}__Message--brand-light & {
            //background-image: url('#{$basepath}svg/icon-hero-sale-black.svg');
        }

        #{$root}__Message--key & {
            content: '';
            background-image: url('#{$basepath}svg/icon-hero-key-white.svg');
            height: 24px;
            width: 24px;
        }

        #{$root}__Message--key#{$root}__Message--brand-light & {
            //background-image: url('#{$basepath}svg/icon-hero-key-black.svg');
        }

        #{$root}__Message--notification & {
            content: '';
            background-image: url('#{$basepath}svg/icon-hero-notification-white.svg');
            height: 24px;
            width: 24px;
        }

        #{$root}__Message--notification#{$root}__Message--brand-light & {
            //background-image: url('#{$basepath}svg/icon-hero-notification-black.svg');
        }

        #{$root}__Message--pokal & {
            content: '';
            background-image: url('#{$basepath}svg/icon-hero-pokal-white.svg');
            height: 20px;
            width: 20px;

        }

        #{$root}__Message--pokal#{$root}__Message--brand-light & {
            //background-image: url('#{$basepath}svg/icon-hero-pokal-black.svg');
        }

        #{$root}__Message--event & {
            content: '';
            background-image: url('#{$basepath}svg/icon-hero-event-white.svg');
            height: 24px;
            width: 24px;
        }

        #{$root}__Message--event#{$root}__Message--brand-light & {
            //background-image: url('#{$basepath}svg/icon-hero-event-black.svg');
        }

        #{$root}__Message--offer & {
            content: '';
            background-image: url('#{$basepath}svg/icon-hero-erbjudande-white.svg');
            height: 20px;
            width: 20px;
        }

        #{$root}__Message--offer#{$root}__Message--brand-light & {
            //background-image: url('#{$basepath}svg/icon-hero-erbjudande-black.svg');
        }
    }

    &__MessageTitle {
        font-size: 1.4rem;
        font-weight: 500;
        margin-bottom: 6px;
    }

    &__MessageText {
        font-size: 1.2rem;
        font-weight: 500;
        line-height: 1.33;
        letter-spacing: 0.4px;
    }

    &__MessageWrapper {
        width: 100%;
        margin-top: auto;

        #{$root}--CardExternalProject & {
            position: absolute;
            left: 0;
            bottom: -30px;
        }
    }
}
