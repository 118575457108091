@import 'styles/includes.folkhem.scss';

.ShortFacts {
    $root: &;

    &--NoStatus {
    }

    &--occupied,
    &--sold-out {
        border-color: $colorBrand;
    }

    &--upcoming,
    &--vip-release {
        border-color: $colorSecondaryLight;
    }

    &--active {
        border-color: $colorSecondary;
    }

    &__Content {
        width: 100%;
    }

    &--CardExternalProject {
        #{$root}__Content,
        #{$root}__MessageWrapper {
            padding: 0;
            margin-top: 24px;

            @include media(md) {
                padding: 0 0 0 $gutter;
            }

            @include media(xl) {
                padding: 0 0 0 $gutter * 2;
            }
        }

    }

    &__Text {
        margin-top: $gutter/2;
        padding-bottom: 28px;
        margin-bottom: 22px;
        border-bottom: 2px solid $colorBlackFill20;

        font-size: 2rem;
        line-height: (24/20);
        @include media(lg) {
            font-size: 2.4rem;
            line-height: (32/24);
        }

        &:empty {
            display: none;
        }

        #{$root}--CardExternalProject#{$root}--MainProject & {
            border-bottom: 0;
        }
    }

    &__Name {
        font-size: 1.6rem;
        font-weight: $fontWeightSemiBold;
        letter-spacing: 0.4px;
        color: rgba($colorBlack, 0.8);
        line-height: 1.5;
        margin-bottom: 4px;

        &:empty {
            display: none;
        }
    }

    &__Area {
        font-size: 1.4rem;
        line-height: 1.7;
        letter-spacing: 0.4px;
        color: $colorBlack;
        opacity: 0.6;
        padding-bottom: 16px;

        &:empty {
            display: none;
        }
    }

    &__Table {
        margin-top: 12px;
    }

    &__TableCell {
        font-size: 1.4rem;
        line-height: (22/14);
        margin-right: $gutter;
        color: $colorBlackFill30;
        min-width: $gutter * 3;

        &--Value {
            width: 100%;
            letter-spacing: 0.4;
            font-size: 1.6rem;
            font-weight: $fontWeightSemiBold;
            color: $colorBlackFill40;
        }
    }

    &__MainProject {
        display: none;
    }

    &__Button {
        border: 2px solid $colorBrand;
        border-radius: 2px;
        background: $colorBrand;
        letter-spacing: 1.3px;
        color: white;
        padding: 14px 30px;
        font-size: 1.6rem;
        line-height: (16/16);
        margin-top: 40px;
        display: inline-block;
    }

    &__Message {
        width: 100%;
        text-align: center;
        padding: 10px 16px 8px;

        &--dark-secondary {
            background-color: $colorSecondaryDark;
            color: #fff;
        }

        &--brand {
            background-color: $colorBrand;
            color: #fff;
        }

        &--brand-light {
            background-color: $colorBrandLight;
            color: #000;
        }
    }

    &__MessageContent {
        text-align: left;
    }

    &__MessageContainer {
        display: flex;
        justify-content: left;
        align-items: normal;
    }

    &__MessageContainer::before {
        margin-right: 16px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;

        #{$root}__Message--sale & {
            content: '';
            background-image: url('#{$basepath}svg/icon-hero-sale-white.svg');
            height: 24px;
            width: 24px;

        }

        #{$root}__Message--sale#{$root}__Message--brand-light & {
            background-image: url('#{$basepath}svg/icon-hero-sale-green.svg');
        }

        #{$root}__Message--key & {
            content: '';
            background-image: url('#{$basepath}svg/icon-hero-key-white.svg');
            height: 24px;
            width: 24px;

        }

        #{$root}__Message--key#{$root}__Message--brand-light & {
            background-image: url('#{$basepath}svg/icon-hero-key-green.svg');
        }

        #{$root}__Message--notification & {
            content: '';
            background-image: url('#{$basepath}svg/icon-hero-notification-white.svg');
            height: 24px;
            width: 24px;

        }

        #{$root}__Message--notification#{$root}__Message--brand-light & {
            background-image: url('#{$basepath}svg/icon-hero-notification-green.svg');
        }

        #{$root}__Message--pokal & {
            content: '';
            background-image: url('#{$basepath}svg/icon-hero-pokal-white.svg');
            height: 24px;
            width: 24px;

        }

        #{$root}__Message--pokal#{$root}__Message--brand-light & {
            background-image: url('#{$basepath}svg/icon-hero-pokal-green.svg');
        }

        #{$root}__Message--event & {
            content: '';
            background-image: url('#{$basepath}svg/icon-hero-event-white.svg');
            height: 24px;
            width: 24px;
        }

        #{$root}__Message--event#{$root}__Message--brand-light & {
            background-image: url('#{$basepath}svg/icon-hero-event-green.svg');
        }

        #{$root}__Message--offer & {
            content: '';
            background-image: url('#{$basepath}svg/icon-hero-erbjudande-white.svg');
            height: 24px;
            width: 24px;
        }

        #{$root}__Message--offer#{$root}__Message--brand-light & {
            background-image: url('#{$basepath}svg/icon-hero-erbjudande-green.svg');
        }
    }

    &__MessageTitle {
        font-size: 1.6rem;
        font-weight: 500;

        #{$root}__Message--brand & {
            color: #fff;
        }
    }

    &__MessageText {
        font-size: 1.2rem;
        line-height: 20px;

        #{$root}__Message--brand & {
            color: #fff;
        }
    }
}
