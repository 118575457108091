@import 'styles/includes.folkhem.scss';

.Card {
    $root: &;

    color: #fff;
    display: block;
    position: relative;
    text-decoration: none;
    width: 100%;
    border-radius: 1px;
    overflow: hidden;

    &::before {
        background-image: url('#{$basepath}img/growth-ring-placeholder.png');
        background-position: center;
        background-size: cover;
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 0;
        transition: transform 0.3s;

        #{$root}--WithinCardProject:hover & {
            transform: scale(1.04);
        }
    }

    &::after {
        content: '';
        display: inline-block;
        height: 0;
        padding-bottom: calc(100% / (832 / 464));

        .CardExternalProject & {
            @include media(lg) {
                padding-bottom: calc(100% / (832 / 464));
            }
        }
    }

    &__Image {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        transition: transform 0.3s;

        #{$root}--WithinCardProject:hover & {
            transform: scale(1.04);
        }

        &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0);
            transition: background-color 0.3s;
            top: 0;
            left: 0;

            #{$root}--WithinCardProject:hover & {
                background-color: rgba(0, 0, 0, 0.08);
            }
        }
    }

    &__Content {
        display: none;
    }
}
