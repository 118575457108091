@import 'styles/includes.nordr.scss';

.Card {
    $root: &;

    color: #fff;
    display: block;
    position: relative;
    text-decoration: none;
    width: 100%;
    border-radius: 1px;
    overflow: hidden;

    &::before {
        background-image: url('#{$basepath}img/planskiss-placeholder.png');
        background-position: center;
        background-size: cover;
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 0;
        transition: transform 0.3s;
        will-change: transform;

        #{$root}--WithinCardProject:hover & {
            transform: scale(1.04);
        }
    }

    &::after {
        content: '';
        display: inline-block;
        height: 0;
        padding-bottom: calc(100% / (433 / 256));
    }

    &--CardExternalProject {
        &::after {
            @include media(lg) {
                padding-bottom: calc(100% / (917 / 424));
            }
        }
    }

    &__Image {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        transition: transform 0.3s;
        will-change: transform;

        #{$root}--WithinCardProject:hover & {
            transform: scale(1.04);
        }

        &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0);
            transition: background-color 0.3s;
            top: 0;
            left: 0;

            #{$root}--WithinCardProject:hover & {
                background-color: rgba(0, 0, 0, 0.08);
            }
        }
    }

    &__Content {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 20px;
        border-radius: 1px;
    }

    &__Title {
        font-size: 1.8rem;
        line-height: 22px;
        text-align: center;
        text-transform: uppercase;

        @include media(md) {
            font-size: 2.4rem;
            line-height: 32px;
        }
    }

    &__Text {
        font-size: 1.4rem;
        line-height: 20px;
    }
}
