// :root {
//     --fontFamilyBase: 'semplicitapro', Arial, sans-serif;
//     --fontBaseColor: #333333;
//     --backgroundColor: transparent;
//     // --colorBrand:
//     // --colorBrandDark:
//     --colorBlack: #000000;
//     --colorWhite: #ffffff;
//     --colorBlueLight100: #5f959d;
//     --colorGradientGreen100: #18576f;
// }

$fontFamilyBase: 'semplicitapro', Arial, sans-serif;
$colorWhite: #ffffff;
$colorBlack: #000000;

$colorGray60: #858585;
$colorGray80: #5c5c5c;
$colorGray100: #333333;

$colorBrownLight20: #f4f3f2;
$colorBrownLight40: #dfdcda;
$colorBrownLight60: #c0bbb8;
$colorBrownLight100: #79736d;

$colorRust20: #f7e2db;
$colorRust40: #f0c5b7;
$colorRust60: #e8a793;
$colorRust80: #d96d4b;
$colorRust100: #bd4b28;
$colorRust110: #ae3c19;

$colorBlueLight20: #dfeaeb;
$colorBlueLight40: #bfd5d8;
$colorBlueLight60: #9fbfc4;
$colorBlueLight100: #5f959d;

$colorBlueDark100: #250f40;
$colorRedLight100: #a87866;
$colorGreenDark100: #306260;

$colorGradientGreen100: #18576f;
/*
    background-image
 */

$gradientBlueGreenAlpha: rgba(37, 17, 65, 0.76), rgba(24, 87, 111, 0.19);
$gradientBlueGreen: $colorBlueDark100, $colorGradientGreen100;
$gradientBlueStrongGreen: $colorBlueDark100, $colorGradientGreen100, $colorGradientGreen100;

$backgdImgGradient: radial-gradient(circle at 20% 0, $gradientBlueGreenAlpha), radial-gradient(circle at 40% 100%, $gradientBlueGreen);
$backgdImgGradientBottom: radial-gradient(circle at 0 0, $gradientBlueGreenAlpha), radial-gradient(circle at 40% 100%, $gradientBlueStrongGreen);
$backgdImgGradientTop: radial-gradient(circle at 40% 0, $gradientBlueGreenAlpha), radial-gradient(circle at 10% 100%, $gradientBlueStrongGreen);
$backgdImgGradientLeft: radial-gradient(circle at 0 50%, $gradientBlueGreenAlpha), radial-gradient(circle at 20% -20%, $gradientBlueGreenAlpha), radial-gradient(circle at 20% 120%, $gradientBlueStrongGreen);

$fontWeightLight: 300;
$fontWeightRegular: 400;
